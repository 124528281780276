exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agendar-consulta-js": () => import("./../../../src/pages/agendar-consulta.js" /* webpackChunkName: "component---src-pages-agendar-consulta-js" */),
  "component---src-pages-areas-atuacao-js": () => import("./../../../src/pages/areas-atuacao.js" /* webpackChunkName: "component---src-pages-areas-atuacao-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-termos-condicoes-js": () => import("./../../../src/pages/termos-condicoes.js" /* webpackChunkName: "component---src-pages-termos-condicoes-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-posts-js": () => import("./../../../src/templates/blog-posts.js" /* webpackChunkName: "component---src-templates-blog-posts-js" */)
}

