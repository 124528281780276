import React from "react"
import {
  AddressBook,
  Briefcase,
  BuildingCommunity,
  Certificate,
  HeartHandshake,
  Hierarchy3,
  Prison,
  User,
  Users,
  World,
} from "tabler-icons-react"

export const colors = {
  text: "#272d38",
  textLight: "#8491a9",
  black: "#222222",
  primary: "#9b5039",
  primaryLight: "#f4e6e1",
  secondary: "#d9a697",
}

const areasAtuacaoIconProps = {
  size: 80,
  color: colors.text,
  strokeWidth: 1,
}

export const iconsAreasAtuacao = {
  civil: <User {...areasAtuacaoIconProps} />,
  penal: <Prison {...areasAtuacaoIconProps} />,
  trabalho: <Briefcase {...areasAtuacaoIconProps} />,
  familia: <Users {...areasAtuacaoIconProps} />,
  sucessao: <Hierarchy3 {...areasAtuacaoIconProps} />,
  fiscal: <AddressBook {...areasAtuacaoIconProps} />,
  internacional: <World {...areasAtuacaoIconProps} />,
  comercial: <HeartHandshake {...areasAtuacaoIconProps} />,
  imobiliario: <BuildingCommunity {...areasAtuacaoIconProps} />,
  registos: <Certificate {...areasAtuacaoIconProps} />,
}
