import "bootstrap/dist/css/bootstrap.min.css"
//import "bootstrap/dist/js/bootstrap.bundle"

import * as React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import "../style/style.scss"
import { motion, AnimatePresence } from "framer-motion"
import { BrandWhatsapp } from "tabler-icons-react"
import { colors } from "../assets/icons"
import Logo from "../images/logo.svg"
import ToastCookies from "./ToastCookies"

const duration = 0.5
const overlayDuration = 1.2
const overlay2Duration = 1.1

/* const pageVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
} */
const overlayVariants = {
  initial: {
    y: "0vh",
  },
  animate: {
    y: "-121vh",
    transition: {
      duration: duration,
      delay: overlayDuration,
      when: "beforeChildren",
    },
  },
  exit: {
    y: "0vh",
    transition: { duration: duration },
  },
}
const overlay2Variants = {
  initial: {
    y: "0vh",
  },
  animate: {
    y: "-121vh",
    transition: {
      duration: duration,
      delay: overlay2Duration,
      when: "beforeChildren",
      ease: "easeInOut",
    },
  },
  exit: {
    y: "0vh",
    transition: { duration: duration, ease: "easeInOut" },
  },
}

const Layout = ({ children, location }) => {
  /* const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) */
  return (
    <>
      <AnimatePresence>
        <motion.div
          key={location.key + "_overlay"}
          style={{ backgroundColor: colors.secondary }}
          className="overlay"
          variants={overlayVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        ></motion.div>
        <motion.div
          key={location.key + "_overlay2"}
          className="overlay"
          variants={overlay2Variants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <img src={Logo} alt={"MMV Advogados"} width={280} className="logo" />
        </motion.div>
        <Header key={location.key + "_header"} />
        <main key={location.key + "_main"}>{children}</main>
        <div className="whatsapp-button" role="button">
          <a
            href="https://api.whatsapp.com/send?phone=915485823&text=Olá, gostava de pedir uma informação rápida."
            target="_blank"
            rel="noreferrer"
          >
            <BrandWhatsapp size={32} color="white" />
          </a>
        </div>
        <ToastCookies key="toastCookies" />
        <Footer key={location.key + "_footer"} />
      </AnimatePresence>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
