import React from "react"

const blobShapes = [
  "M56.2,-48.7C66.7,-31.7,64.8,-8.6,55.5,4.8C46.1,18.2,29.3,22,12,33.7C-5.3,45.4,-23,65,-38,63.6C-53.1,62.3,-65.5,40,-67.1,19.2C-68.7,-1.6,-59.6,-20.9,-46.6,-38.5C-33.6,-56.1,-16.8,-71.9,3,-74.4C22.9,-76.8,45.7,-65.7,56.2,-48.7Z",
  "M40.2,-34.7C50.9,-18.6,57.6,-2,54.2,12.3C50.9,26.6,37.6,38.6,23,44.3C8.3,50.1,-7.7,49.7,-27.3,44.8C-46.9,40,-70,30.6,-75.5,14.8C-81,-0.9,-68.9,-23.1,-53.4,-40.3C-37.9,-57.5,-18.9,-69.7,-2.1,-68C14.7,-66.4,29.5,-50.8,40.2,-34.7Z",
  "M50.3,-27.1C63.9,-5.6,72.5,20.6,63.3,33.7C54.1,46.7,27.1,46.5,1.3,45.8C-24.5,45.1,-49.1,43.8,-59.3,30.2C-69.5,16.5,-65.4,-9.4,-53.1,-30.1C-40.9,-50.8,-20.4,-66.3,-1,-65.7C18.4,-65.1,36.8,-48.5,50.3,-27.1Z",
  "M49.6,-41.9C57.8,-29.4,53.3,-9.1,49.3,12.4C45.3,33.9,41.8,56.6,29.3,63.7C16.9,70.8,-4.5,62.3,-24.1,52C-43.6,41.8,-61.3,29.9,-66.9,13.1C-72.5,-3.8,-66,-25.6,-52.8,-39.3C-39.7,-52.9,-19.8,-58.4,0.5,-58.7C20.7,-59.1,41.5,-54.3,49.6,-41.9Z",
  "M22.5,-20.5C31.2,-7.1,41.6,2.4,40.8,10.7C40,19,28,26.2,14.6,34.1C1.2,42,-13.6,50.6,-30.9,48.3C-48.1,46.1,-67.7,33,-72.7,15.4C-77.7,-2.2,-68,-24.4,-53.5,-39.2C-38.9,-53.9,-19.5,-61.3,-6.3,-56.3C6.9,-51.3,13.9,-33.9,22.5,-20.5Z",
]

const Blob = ({ size, fill, color, borderWidth, blobShape }) => {
  return (
    <svg
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}>
      <path
        fill={fill ? color : "transparent"}
        stroke={color}
        strokeWidth={borderWidth ? borderWidth : 0}
        d={blobShapes[blobShape]}
        transform="translate(100 100)"
      />
    </svg>
  )
}

export default Blob
