import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Toast } from "react-bootstrap"

function ToastCookies() {
  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(!localStorage.getItem("cookie"))
  }, [])

  const accept = () => {
    localStorage.setItem("cookie", JSON.stringify(true))
    setShow(false)
  }

  return (
    <Toast show={show} onClose={() => setShow(false)} className="toast-cookies">
      <Toast.Body className="ps-md-3 pe-md-3">
        <div className="row">
          <div className="col-12 d-flex flex-column">
            Este site usa cookies para garantir que tenhas a melhor experiência.
            <Link to="/politica-cookies">Saber mais</Link>
          </div>
          <div className="col-12 text-end pt-md-0">
            <button className="main-link p-0" onClick={() => accept()}>
              Aceitar —
            </button>
          </div>
        </div>
      </Toast.Body>
    </Toast>
  )
}

export default ToastCookies
