/* // custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
 */

import React from "react"
import Layout from "./src/components/Layout"

const transitionDelay = 500

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (window !== "undefined") {
    if (location.hash === "") {
      if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
      } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
          () => window.scrollTo(...(savedPosition || [0, 0])),
          transitionDelay
        )
      }
    } else {
      scrollTo(location.hash)
    }
  }

  return false
}

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}
